export default {
    countryKey: "国家主键(c_country)",
    countryCode: "国家代码",
    unCode: "联合国港口代码",
    name: "名称",
    timeZone: "时区",
    portType: "port类型",
    lon: "经度",
    lat: "纬度",
    available: "是否有效"
}
