import Vue from "vue";
import display from "@/configure/display/common/port";
import {PortEntity, PortQuery} from "@/core/entity/common/port";
import {DataOptions, DataTableHeader} from "vuetify";
import service from "@/core/service";
import tip from "@/configure/tip";

export default Vue.extend({
    data: () => ({
        show: true,
        loading: false,
        headers: <DataTableHeader[]>[
            {text: display.countryCode, value: 'countryCode'},
            {text: display.unCode, value: 'unCode'},
            {text: display.name, value: 'name'},
            {text: display.timeZone, value: 'timeZone'},
            {text: display.portType, value: 'portType'},
            {text: display.lon, value: 'lon'},
            {text: display.lat, value: 'lat'},
            {
                text: '',
                value: 'action',
                cellClass: ["d-flex", "justify-space-around", "align-center"],
                sortable: false,
                width: 140,
                align: 'center'
            },
        ],
        options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10},
        data: <PortQuery>{},
        result: <PortEntity[]>[],
        resultLength: 0,
        datetime: "",
        display
    }),
    methods: {
        async query() {
            this.loading = true;
            try {
                // console.log(this.data.name);
                const result = await service.port.query(this.$queryData(this.data, this.options, ["name"]));
                this.result = result;
                this.resultLength = result.dataLength!;
            } finally {
                this.loading = false;
            }
        },
        async add() {
            await this.$router.push({path: "/port/add"});
        },
        async load(key: number) {
            await this.$router.push({path: "/port/load/" + key});
        },
        async edit(key: number) {
            await this.$router.push({path: "/port/edit/" + key});
        },
        async del(key: number) {
            if (await this.$confirm(tip.confirm.del)) {
                await service.port.del(key);
                await this.query();
            }
        },
    }
});
